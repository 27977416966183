import last from 'lodash/last';

import { BrandModel } from 'explorer/types/Brand';

import { BrandPageEntry } from 'lib/contentful';

import { Brand, MerchantSubscriptionTier, Profile } from 'types/generated/api';

import { pageRoutes } from '../../../lib/routes';
import {
  displayUrlToCanonicalUrl,
  extractQueryParams,
} from '../../../lib/routes/utils';

const FILTERS_FOR_TITLE_MODIFIER = ['sc2'];

/**
 * Map ApiBrand to BrandModel
 */
export const mapApiBrand = (
  apiBrand: Brand | null | undefined
): Partial<BrandModel> => {
  if (!apiBrand) {
    return {};
  }

  const model: Partial<BrandModel> = {
    subscriptionTier: apiBrand.subscriptionTier ?? undefined,
    title: apiBrand.label ?? undefined,
  };
  return model;
};

/**
 * Map Contentful BrandEntry to BrandModel
 */
export const mapContentfulBrand = (
  brandEntry: BrandPageEntry | null | undefined
): Partial<BrandModel> => {
  if (!brandEntry) {
    return {};
  }

  const brandPick = brandEntry?.fields.picks?.fields;
  const model: Partial<BrandModel> = {
    description: brandPick?.description,
    facebookUrl: brandEntry?.fields.facebookUrl,
    facetList: brandEntry?.fields.facetList,
    homepageUrl: brandEntry?.fields.homepageUrl,
    imageUrl: brandPick?.image?.fields.file.url,
    instagramUrl: brandEntry?.fields.instagramUrl,
    metaDescription: brandPick?.metaDescription,
    metaTitle: brandPick?.metaTitle,
    plpMarketingCards: brandEntry?.fields.plpMarketingCards,
    title: brandPick?.title,
    twitterUrl: brandEntry?.fields.twitterUrl,
  };
  return model;
};

/**
 * Map Profile to BrandModel
 */
export const mapProfileBrand = (
  profileData: Partial<Profile> | null | undefined
): Partial<BrandModel> => {
  if (!profileData) {
    return {};
  }

  const model: Partial<BrandModel> = {
    description: profileData.biography ?? undefined,
    facebookUrl: toFacebookUrl(profileData.facebookName),
    homepageUrl: profileData.website ?? undefined,
    imageUrl: profileData.coverImageAsset?.url,
    instagramUrl: toInstagramUrl(profileData.instagramName),
    metaDescription: profileData.metaDescription ?? undefined,
    metaTitle: profileData.metaTitle ?? undefined,
    profileImageUrl: profileData.profileAsset?.url,
    title: profileData.displayName ?? undefined,
    twitterUrl: toTwitterUrl(profileData.twitterName),
  };
  return model;
};

const toFacebookUrl = (facebookName?: string | null) => {
  return facebookName ? `https://www.facebook.com/${facebookName}/` : undefined;
};

const toInstagramUrl = (instagramName?: string | null) => {
  return instagramName
    ? `https://www.instagram.com/${instagramName}/`
    : undefined;
};

const toTwitterUrl = (twitterName?: string | null) => {
  return twitterName ? `https://www.twitter.com/${twitterName}/` : undefined;
};

/**
 * Returns a string of title modifiers based on provided query parameters.
 * If no valid string can be found, it returns undefined.
 */
export const getBrandPageTitleModifiers = (queryParams: {
  [key: string]: string;
}): string | void => {
  let eligibleQueryParamCount = 0;
  let titleModifier;
  for (const filter of FILTERS_FOR_TITLE_MODIFIER) {
    const queryParam = queryParams[filter];
    // "~" is used to separate filter search term, e.g., color=white~blue
    if (queryParam?.split('~').length === 1) {
      eligibleQueryParamCount++;
      titleModifier = last(queryParam.split('>'))?.trim();
    }
  }
  if (eligibleQueryParamCount === 1) {
    return titleModifier;
  }
};

export const getCanonicalUrlForBrandPage = (
  currentUrl: string,
  brandSlug: string
) => {
  const currentQueryParams = extractQueryParams(currentUrl);
  const canonicalUrl = displayUrlToCanonicalUrl(
    pageRoutes.brand.detail.landing.displayUrl(brandSlug),
    currentQueryParams,
    true /* disallowMultipleQueryParams */
  );
  return canonicalUrl;
};

export const isVIPBrandModel = (
  brandModel: BrandModel | undefined
): boolean => {
  return brandModel?.subscriptionTier === MerchantSubscriptionTier.TIER_2;
};

export const isVIPBrand = (
  subscriptionTier: MerchantSubscriptionTier | null | undefined
): boolean => {
  return subscriptionTier === MerchantSubscriptionTier.TIER_2;
};
